<script>
export default {
  props: {
    name: String
  }
}
</script>

<template>
  <div :class="'label-status ' + 'label-status--' + this.name.toLowerCase()">
    {{ this.name }}
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/css/lh-ds/core/functions' as *;

.label-status {
  display: inline-block;
  border: 1px solid;
  padding: 4px 8px;
  border-radius: 2px;

  &--completed, &--true {
    color: #00965E;
  }

  &--released {
    color: #0089FF;
  }

  &--draft {
    color: #AAA;
  }

  &--rejected {
    color: #FF2000;
  }
  &--blocked {
    color: #FF2000;
  }

  &--scheduled, &--false, &--loading {
    color: #000000;
  }
  
  &--ready {
    color: #0fbd51;
  }

  &--shipping {
    color: #e08600;
  }

  &--arrived {
    color: #078bfe;
  }

  &--finished {
    color: #0a9964;
  }

  &--deleted {
    color: #a9a9a9;
  }

  &--onhold {
    color: #e18600;
  }

  &--returned {
    color: #FF2000;
  }

  &--cancelled {
    color: #FF2000;
  }

}
</style>