'use strict';
import { app } from '@/main.js'
const MasterDataService = {

    getShipto(shipto) {
        return new Promise((resolve, reject) => {
            app.config.globalProperties.$axios.get(`/masterData/searchShipto/${shipto}`)
                .then(response => resolve(response))
                .catch(err => { reject({ ret: err.response.status, msg: err.response.data }) })
        })
    }
}

export default MasterDataService;