let numberMask = JSON.parse(localStorage.getItem("numberFormat")) || "de-DE";
export const weightCoversion = (weight, unitFrom, unitTo) => {
    let str = strCreation(weight, unitFrom, unitTo);
    return str;
}

const strCreation = (weight, unitFrom, unitTo) => {

    if (unitFrom === 'KGM') {
        return returnKGMConversion(weight, unitTo)
    } else if (unitFrom === 'T') {
        return returnTONConversion(weight, unitTo)
    }
}

const returnKGMConversion = (weight, unitTo) => {
    let str = '';
    if (unitTo === 'T') {
        let weightAux = Number(weight) / 1000;
        str = weightAux.toFixed(3).toLocaleString(numberMask) + ' TNE'
    } else {
        str = Number(weight).toLocaleString(numberMask) + ' KGM'
    }

    return str
}

const returnTONConversion = (weight, unitTo) => {
    let str = '';
    weight = Math.round(weight);
    if (unitTo === 'KGM') {
        let weightAux = Number(weight) * 1000;
        str = weightAux.toLocaleString(numberMask, { minimumFractionDigits: 2 }) + ' KGM';
    } else {
        str = Number(weight).toLocaleString(numberMask, { minimumFractionDigits: 2 }) + ' TNE';
    }

    return str
}

export const getUnitISOCode = (e1Code) => {

    const myCodes = [
        {
            e1: 'TM',
            iso: 'TNE'
        },
        {
            e1: 'KG',
            iso: 'KGM'
        },
        {
            e1: 'EA',
            iso: 'Units'
        }
    ];

    let code = myCodes.find((code) => code.e1 == e1Code);

    return code ? code.iso : e1Code;
}